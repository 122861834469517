/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { PostFieldsFragmentDoc } from '../post/posts.generated';
import {
  PageInfoFieldsFragmentDoc,
  PostMeiliPageInfoFieldsFragmentDoc
} from '../../graphql/fragments/page-info-fields.fragment.generated';
import { api } from 'src/api/baseApi';
export type TimelineQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type TimelineQuery = {
  __typename?: 'Query';
  timeline: {
    __typename?: 'TimelineItem';
    id: string;
    data?: {
      __typename?: 'Post';
      id: string;
      content: string;
      repostCount?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      totalComments: number;
      createdAt: any;
      updatedAt: any;
      followPostOwner?: boolean | null;
      followedPage?: boolean | null;
      followedToken?: boolean | null;
      originalLanguage?: string | null;
      danaViewScore?: number | null;
      uploads?: Array<{
        __typename?: 'UploadDetail';
        id: string;
        upload: {
          __typename?: 'Upload';
          id: string;
          sha: string;
          bucket?: string | null;
          width?: number | null;
          height?: number | null;
          cfImageId?: string | null;
          cfImageFilename?: string | null;
        };
      }> | null;
      postAccount: {
        __typename?: 'Account';
        address: string;
        id: number;
        name: string;
        avatar?: string | null;
        createCommentFee?: string | null;
      };
      page?: {
        __typename?: 'Page';
        avatar?: string | null;
        name: string;
        id: string;
        createPostFee: string;
        createCommentFee: string;
        pageAccount: { __typename?: 'Account'; id: number; name: string; address: string };
      } | null;
      token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
      reposts?: Array<{
        __typename?: 'Repost';
        accountId?: number | null;
        account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
      }> | null;
      translations?: Array<{
        __typename?: 'PostTranslation';
        id: string;
        translateContent?: string | null;
        translateLanguage?: string | null;
      }> | null;
    } | null;
  };
};

export type HomeTimelineQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  level?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type HomeTimelineQuery = {
  __typename?: 'Query';
  homeTimeline: {
    __typename?: 'TimelineItemConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'TimelineItemEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data?: {
          __typename?: 'Post';
          id: string;
          content: string;
          repostCount?: number | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          totalComments: number;
          createdAt: any;
          updatedAt: any;
          followPostOwner?: boolean | null;
          followedPage?: boolean | null;
          followedToken?: boolean | null;
          originalLanguage?: string | null;
          danaViewScore?: number | null;
          uploads?: Array<{
            __typename?: 'UploadDetail';
            id: string;
            upload: {
              __typename?: 'Upload';
              id: string;
              sha: string;
              bucket?: string | null;
              width?: number | null;
              height?: number | null;
              cfImageId?: string | null;
              cfImageFilename?: string | null;
            };
          }> | null;
          postAccount: {
            __typename?: 'Account';
            address: string;
            id: number;
            name: string;
            avatar?: string | null;
            createCommentFee?: string | null;
          };
          page?: {
            __typename?: 'Page';
            avatar?: string | null;
            name: string;
            id: string;
            createPostFee: string;
            createCommentFee: string;
            pageAccount: { __typename?: 'Account'; id: number; name: string; address: string };
          } | null;
          token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
          reposts?: Array<{
            __typename?: 'Repost';
            accountId?: number | null;
            account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
          }> | null;
          translations?: Array<{
            __typename?: 'PostTranslation';
            id: string;
            translateContent?: string | null;
            translateLanguage?: string | null;
          }> | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export const TimelineDocument = `
    query Timeline($id: String!) {
  timeline(id: $id) {
    id
    data {
      ... on Post {
        ...PostFields
      }
    }
  }
}
    ${PostFieldsFragmentDoc}`;
export const HomeTimelineDocument = `
    query HomeTimeline($after: String, $first: Int = 20, $level: Int) {
  homeTimeline(after: $after, first: $first, level: $level) {
    totalCount
    edges {
      cursor
      node {
        id
        data {
          ... on Post {
            ...PostFields
          }
        }
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${PostFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    Timeline: build.query<TimelineQuery, TimelineQueryVariables>({
      query: variables => ({ document: TimelineDocument, variables })
    }),
    HomeTimeline: build.query<HomeTimelineQuery, HomeTimelineQueryVariables | void>({
      query: variables => ({ document: HomeTimelineDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useTimelineQuery, useLazyTimelineQuery, useHomeTimelineQuery, useLazyHomeTimelineQuery } =
  injectedRtkApi;
