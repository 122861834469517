/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { api } from 'src/api/baseApi';
export type GetAccountByAddressQueryVariables = Types.Exact<{
  address: Types.Scalars['String'];
}>;

export type GetAccountByAddressQuery = {
  __typename?: 'Query';
  getAccountByAddress: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: { __typename?: 'AccountDana'; danaGiven?: number | null; danaReceived?: number | null } | null;
  };
};

export type AccountFieldsFragment = {
  __typename?: 'Account';
  id: number;
  name: string;
  address: string;
  mnemonicHash?: string | null;
  encryptedMnemonic?: string | null;
  encryptedSecret?: string | null;
  publicKey?: string | null;
  language: string;
  followersCount?: number | null;
  followingsCount?: number | null;
  followingPagesCount?: number | null;
  createdAt: any;
  updatedAt: any;
  avatar?: string | null;
  cover?: string | null;
  pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
  accountDana?: { __typename?: 'AccountDana'; danaGiven?: number | null; danaReceived?: number | null } | null;
};

export type CreateAccountMutationVariables = Types.Exact<{
  input: Types.CreateAccountInput;
}>;

export type CreateAccountMutation = {
  __typename?: 'Mutation';
  createAccount: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: { __typename?: 'AccountDana'; danaGiven?: number | null; danaReceived?: number | null } | null;
  };
};

export type ImportAccountMutationVariables = Types.Exact<{
  input: Types.ImportAccountInput;
}>;

export type ImportAccountMutation = {
  __typename?: 'Mutation';
  importAccount: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: { __typename?: 'AccountDana'; danaGiven?: number | null; danaReceived?: number | null } | null;
  };
};

export type UpdateAccountMutationVariables = Types.Exact<{
  input: Types.UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: { __typename?: 'AccountDana'; danaGiven?: number | null; danaReceived?: number | null } | null;
  };
};

export const AccountFieldsFragmentDoc = `
    fragment AccountFields on Account {
  id
  name
  address
  mnemonicHash
  encryptedMnemonic
  encryptedSecret
  publicKey
  language
  pages {
    id
    name
  }
  followersCount
  followingsCount
  followingPagesCount
  createdAt
  updatedAt
  avatar
  cover
  accountDana {
    danaGiven
    danaReceived
  }
}
    `;
export const GetAccountByAddressDocument = `
    query getAccountByAddress($address: String!) {
  getAccountByAddress(address: $address) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const CreateAccountDocument = `
    mutation createAccount($input: CreateAccountInput!) {
  createAccount(data: $input) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const ImportAccountDocument = `
    mutation importAccount($input: ImportAccountInput!) {
  importAccount(data: $input) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const UpdateAccountDocument = `
    mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(data: $input) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getAccountByAddress: build.query<GetAccountByAddressQuery, GetAccountByAddressQueryVariables>({
      query: variables => ({ document: GetAccountByAddressDocument, variables })
    }),
    createAccount: build.mutation<CreateAccountMutation, CreateAccountMutationVariables>({
      query: variables => ({ document: CreateAccountDocument, variables })
    }),
    importAccount: build.mutation<ImportAccountMutation, ImportAccountMutationVariables>({
      query: variables => ({ document: ImportAccountDocument, variables })
    }),
    updateAccount: build.mutation<UpdateAccountMutation, UpdateAccountMutationVariables>({
      query: variables => ({ document: UpdateAccountDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const {
  useGetAccountByAddressQuery,
  useLazyGetAccountByAddressQuery,
  useCreateAccountMutation,
  useImportAccountMutation,
  useUpdateAccountMutation
} = injectedRtkApi;
