/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

export type PageInfoFieldsFragment = {
  __typename?: 'PageInfo';
  endCursor?: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string | null;
};

export type PostMeiliPageInfoFieldsFragment = {
  __typename?: 'PostMeiliPageInfo';
  endCursor?: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string | null;
};

export const PageInfoFieldsFragmentDoc = `
    fragment PageInfoFields on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `;
export const PostMeiliPageInfoFieldsFragmentDoc = `
    fragment PostMeiliPageInfoFields on PostMeiliPageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `;
