export const OPTION_BURN_VALUE = {
  LIKE: '1',
  DISLIKE: '1',
  LOVE: '10'
};

export const OPTION_BURN_TYPE = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
  LOVE: 'LOVE'
};

export const BurnForTypeString = {
  post: 'post',
  comment: 'comment'
};
